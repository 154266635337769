import { GatsbyLink } from 'components/Links';
import { stringToAnchorId } from 'helpers/stringToAnchorId';

import type { FC } from 'react';
import type { Clause, ClauseContent } from 'components/List';

const getSectionLink = (id: string) =>
  `/sharesight-professional-terms-of-use/#${stringToAnchorId(id)}`;
const getLinkTitle = (id: string) => `${id} | Pro Terms of Use | Sharesight`;
const getClauseId = (clauseNumber: string) => `clause ${clauseNumber}`;

const headerLink = (text: string): Clause => ({
  id: text,
  content: (
    <GatsbyLink textColor="black" to={getSectionLink(text)} title={getLinkTitle(text)}>
      {text}
    </GatsbyLink>
  ),
});

/**
 * children is the identifier of the clause, e.g. '1.1' or '2.4.a'
 */
const ClauseLink: FC<{ children: string }> = ({ children }) => (
  <GatsbyLink to={getSectionLink(getClauseId(children))} title={getLinkTitle(children)}>
    {children}
  </GatsbyLink>
);

export const proTermsOfUseHeaders: Clause[] = [
  headerLink('General'),
  headerLink('Your obligations'),
  headerLink('Our obligations'),
  headerLink('Fees and charges'),
  headerLink('Intellectual property'),
  headerLink('Links to and from Sharesight'),
  headerLink('Disclaimer and limitation of liability'),
  headerLink('Termination'),
  headerLink('Disputes'),
  headerLink('Miscellaneous'),
];

export const proTermsOfUseClauses: ClauseContent[] = [
  {
    header: 'General',
    clauses: [
      {
        id: getClauseId('1.1'),
        content: `In these Terms of Use “we”, “us” and “our” refer to Sharesight Pty Limited and Sharesight Limited, and “Sharesight” refers to the internet sites at sharesight.com or any other site operated by us, together with the software and any information that can be accessed on those sites. References to “you” or “your” are references to the Sharesight Professional Partner.`,
      },
      {
        id: getClauseId('1.2'),
        content: `These Terms of Use set out the terms on which we grant you access to, and use of, Sharesight and together with the Terms of Agreement constitute the entire agreement between you and us.`,
      },
      {
        id: getClauseId('1.3'),
        content: `By accessing or using Sharesight, you agree to be bound by these Terms of Use. If you do not agree to these Terms of Use (or any revisions to the Terms of Use on Sharesight), you must not use Sharesight or any information obtained as a result of the use of Sharesight.`,
      },
      {
        id: getClauseId('1.4'),
        content: (
          <>
            We plan to develop Sharesight over time and these Terms of Use will apply to any changes
            to Sharesight. We reserve the right to revise these Terms of Use from time to time by
            updating the Terms of Use as displayed on Sharesight at any given time. You will be
            bound by any such revised Terms of Use. If you reasonably consider that a material
            change to these Terms of Use will have a material effect on you, then you may terminate
            your account in accordance with clause <ClauseLink>8.5</ClauseLink>. To the extent that
            your clients use or access Sharesight, it is your responsibility to advise your clients
            of any changes to these Terms of Use.
          </>
        ),
      },
      {
        id: getClauseId('1.5'),
        content: `It is your obligation to ensure that you have read and agree to the most recent Terms of Use available on Sharesight. By registering for a Sharesight account, you acknowledge that you have read and understood these Terms of Use and have the authority to act on behalf of any person for whom you are using Sharesight. You are deemed to have agreed to these Terms of Use on behalf of any entity for whom you use Sharesight.`,
      },
      {
        id: getClauseId('1.6'),
        content: `We reserve the right to accept or decline on any grounds we consider appropriate, any organisation or individual seeking to become a Sharesight Professional Partner.`,
      },
      {
        id: getClauseId('1.7'),
        content: `You may not use Sharesight or accept the Terms of Use if:`,
        subclauses: [
          {
            id: getClauseId('2.9.a'),
            content: `you are younger than 18; or`,
          },
          {
            id: getClauseId('2.9.b'),
            content: `you are not of legal age to form a binding contract with Sharesight.`,
          },
        ],
      },
    ],
  },
  {
    header: 'Your obligations',
    clauses: [
      {
        id: getClauseId('2.1'),
        content: `You may only access and use the Sharesight Professional Partner Plan to manage client portfolios and you are responsible for all activity on your account.`,
      },
      {
        id: getClauseId('2.2'),
        content: `You must ensure that all information provided by you during the sign-up process is true and correct to the best of your knowledge and belief.`,
      },
      {
        id: getClauseId('2.3'),
        content: `You can have only one account with Sharesight. By registering with Sharesight, you confirm that you have not previously registered with Sharesight, or if you have previously registered with Sharesight, these earlier account(s) have been cancelled.`,
      },
      {
        id: getClauseId('2.4'),
        content: `You must ensure that your access to and use of Sharesight is not illegal or prohibited by any laws which apply to you.`,
      },
      {
        id: getClauseId('2.5'),
        content: `You must take your own precautions to ensure that the process which you employ for accessing Sharesight does not expose you to risk of viruses or other forms of interference which may damage your systems or data. We take no responsibility for any such damage caused to your systems or data.`,
      },
      {
        id: getClauseId('2.6'),
        content: (
          <>
            You must make reasonable efforts to investigate and diagnose technical problems before
            contacting us. If you still need technical support, please check the support provided
            online by us or contact Sharesight’s customer support, if this is available as part of
            your Subscription Plan. Sharesight has no obligation to provide customer support
            services to you for any third party services or software not owned by us that integrates
            into Sharesight (<strong>Third Party Services</strong>).
          </>
        ),
      },
      {
        id: getClauseId('2.7'),
        content: `You acknowledge that you are solely responsible for the accuracy of data that you enter into Sharesight and all results and analysis from your use of Sharesight.`,
      },
      {
        id: getClauseId('2.8'),
        content: `You acknowledge that you are responsible for your use of Sharesight and for maintaining the confidentiality of passwords and any other information. You will immediately notify us of any unauthorised use of your account or any other breach of security that you become aware of. Sharesight may reset your password following a breach of security and you must take all other actions that Sharesight reasonably deems necessary and advises to you.`,
      },
      {
        id: getClauseId('2.9'),
        content: `You agree that if any data is provided to Sharesight by you, including data provided for or on behalf of any third party, all applicable consents (including privacy) have been obtained regarding the data. You agree that you have the right to disclose the data and the right to grant the use of such data to Sharesight.`,
      },
      {
        id: getClauseId('2.10'),
        content: `When accessing and using Sharesight, you must:`,
        subclauses: [
          {
            id: getClauseId('2.10.a'),
            content: `not attempt to gain unauthorised access to any information, or attempt to undermine the security, integrity or functionality of Sharesight;`,
          },
          {
            id: getClauseId('2.10.b'),
            content: `not use, or misuse Sharesight in any way which may impair the functionality of Sharesight; and`,
          },
          {
            id: getClauseId('2.10.c'),
            content: `not transmit, or input into Sharesight any files that may damage any other person’s computing devices or software, content that may be offensive or material or data in violation of any law.`,
          },
        ],
      },
      {
        id: getClauseId('2.11'),
        content: `You agree that to the extent that you authorise that a third party access Sharesight or your Sharesight account on your behalf (an Account User):`,
        subclauses: [
          {
            id: getClauseId('2.11.a'),
            content: `you are responsible for controlling all Account Users’ level of access to Sharesight at all times;`,
          },
          {
            id: getClauseId('2.11.b'),
            content: `you are responsible for all Account Users’ use of Sharesight, and we have no responsibility or liability for the actions of any Account User;`,
          },
          {
            id: getClauseId('2.11.c'),
            content: `you are responsible for all Account Users’ access to data you have input into Sharesight and you agree that we have no obligation to provide any Account User to access to such data without your authorisation or as required by law; and`,
          },
          {
            id: getClauseId('2.11.d'),
            content: `we will not be a party to any dispute between you and an Account User for any reason.`,
          },
        ],
      },
    ],
  },
  {
    header: 'Our obligations',
    clauses: [
      {
        id: getClauseId('3.1'),
        content: (
          <>
            The data you enter will be governed in accordance with our{' '}
            <GatsbyLink to="/privacy-policy">Privacy Policy</GatsbyLink>.
          </>
        ),
      },
      {
        id: getClauseId('3.2'),
        content: `Sharesight uses ‘cookies’ and other measures to monitor traffic patterns and to serve you more efficiently when you visit Sharesight. Cookies do not contain information by which we can identify you but they identify your computer to our servers. If you refuse cookies some parts of Sharesight may not function properly.`,
      },
      {
        id: getClauseId('3.3'),
        content: `We will ensure information submitted on the login and signup pages is transferred between you and our servers using HTTPS encryption or similar.`,
      },
      {
        id: getClauseId('3.4'),
        content: `We will adhere to accepted industry practices and procedures for preventing data loss, including a daily system back-up regime, but do not make any guarantees that there will be no loss or corruption of data that you have input into Sharesight. We expressly exclude liability for any loss or corruption of data, no matter how caused. You should ensure that you keep up-to-date records of all your data inputted into Sharesight.`,
      },
      {
        id: getClauseId('3.5'),
        content: `We intend that Sharesight should be available 99% of the time, 24 hours a day, seven days a week. However, it is possible that on occasions, Sharesight may be unavailable. If we have to interrupt your use or access to Sharesight for longer periods than we usually anticipate, we will use reasonable endeavours to publish details of such activity on Sharesight in advance. We will have no liability to you for the unavailability of Sharesight.`,
      },
      {
        id: getClauseId('3.6'),
        content: `We have no responsibility to any person other than you and nothing in these Terms of Use confers a benefit to any person other than you.`,
      },
    ],
  },
  {
    header: 'Fees and charges',
    clauses: [
      {
        id: getClauseId('4.1'),
        content: (
          <>
            We will charge you, and you agree to pay, the Subscription Fee for the Professional
            Partner Plan level selected (the <strong>Subscription Plan</strong>) when subscribing to
            Sharesight or as otherwise agreed in writing (<strong>Subscription Fee</strong>).
          </>
        ),
      },
      {
        id: getClauseId('4.2'),
        content: `All fees and charges and all prices for access to Sharesight exclude GST where applicable.`,
      },
      {
        id: getClauseId('4.3'),
        content: `You authorise us to invoice and receive payment from you by direct debit and in advance for Subscription Fees on a monthly basis, annual basis or as otherwise agreed in writing, until termination of your Sharesight account and you cease to use Sharesight or if you downgrade to a free Subscription Plan.`,
      },
      {
        id: getClauseId('4.4'),
        content: `Your credit card details will be encrypted and securely stored by a third party payment solutions provider approved by us. We will not store or have access to your credit card details. In supplying your credit card details to our payment services provider, you authorise us to invoice and the payment service provider to process payment of your Subscription Fees and any other outstanding amounts (as applicable) as they become due.`,
      },
      {
        id: getClauseId('4.5'),
        content: `Invoices are calculated on a monthly or annual basis, based on your Subscription Plan or as otherwise agreed in writing. Where you upgrade your Subscription Plan, any additional costs payable by you will be prorated for the remaining period of the calendar month or year (as applicable) in which you upgrade your Subscription Plan, and you will be billed on the basis of whole calendar months or years (as applicable) going forward, unless otherwise agreed in writing.`,
      },
      {
        id: getClauseId('4.6'),
        content: `If we are unable to successfully process your credit card payment for the Subscription Fee, then we may notify you of the dishonoured payment and cancel your subscription.`,
      },
      {
        id: getClauseId('4.7'),
        content: `You must not pay, or attempt to pay, the Subscription Fee through any fraudulent or unlawful means.`,
      },
      {
        id: getClauseId('4.8'),
        content: `Changing your Subscription Plan may cause loss of content, features, or capacity of the service you receive on Sharesight, and we do not accept any liability for losses arising as a direct or indirect consequence of this.`,
      },
      {
        id: getClauseId('4.9'),
        content: `No refunds or credits for your Subscription Fee will be provided if you elect to downgrade or terminate your Subscription Plan. In the case of a downgrade, the new Subscription Fee for the downgraded Subscription Plan will apply from the beginning of the calendar month or year following the month or year (as applicable) in which the Subscription Plan was downgraded.`,
      },
    ],
  },
  {
    header: 'Intellectual property',
    clauses: [
      {
        id: getClauseId('5.1'),
        content: `We are the owners of all intellectual property rights used on or in connection with Sharesight, including (but not limited to) the copyright material, patents and trademarks used on Sharesight.`,
      },
      {
        id: getClauseId('5.2'),
        content: `We have no intellectual property rights in the data input by you into Sharesight, other than to hold and make it available for the purposes contemplated by these Term of Use or for which it was provided.`,
      },
      {
        id: getClauseId('5.3'),
        content: `No licence is granted to any person in respect of the intellectual property rights used on Sharesight as a result of publication on Sharesight. If you wish to use any intellectual property of a third party you will need to obtain the consent of that third party.`,
      },
      {
        id: getClauseId('5.4'),
        content: (
          <>
            You agree and acknowledge that you will not adapt, reproduce, store, distribute,
            display, publish or create derivative works from any information on Sharesight without
            our prior written permission (other than information which is your data as described in
            clause <ClauseLink>3.1</ClauseLink>).
          </>
        ),
      },
      {
        id: getClauseId('5.5'),
        content: `Unless otherwise stated, you are permitted to access, view, copy, print (in limited quantities) or temporarily store textual material published by us on Sharesight for your professional use as a Sharesight Professional Partner only. Any copyright notice on that information must be retained on the copy.`,
      },
    ],
  },
  {
    header: 'Links to and from Sharesight',
    clauses: [
      {
        id: getClauseId('6.1'),
        content: `From time to time Sharesight may include embedded content to, and links from, other websites. Those websites are not under our control. The links and embedded content are provided for your convenience only. We do not endorse, and are not responsible for the content, validity, accuracy or your use of, those websites. You should check the terms and conditions of use of those websites before you use them.`,
      },
      {
        id: getClauseId('6.2'),
        content: `You may not create a link to Sharesight from another website (other than to the home page) without our prior written consent, which may be given or withheld at our sole discretion.`,
      },
    ],
  },
  {
    header: 'Disclaimer and limitation of liability',
    clauses: [
      {
        id: getClauseId('7.1'),
        content: `We are not a financial product advisory service and Sharesight does not provide financial product advice. The information provided on Sharesight is numeric information only based on publicly available data, data entered by the user or data otherwise available to us and the information presented in the reports is derived from that information as a result of Sharesight’s performance methodology and commonly used calculations. This information is not a recommendation nor is it a statement of opinion. The information should not be relied upon for making investment decisions and it is recommended that you seek your own independent financial advice before making any decisions in relation to your clients’ investments. It is your responsibility to determine that Sharesight meets the needs of your business and your clients.`,
      },
      {
        id: getClauseId('7.2'),
        content: `Although the Sharesight taxation reports may be used to compute taxation information, the information provided is not conclusive and does not constitute tax advice. Sharesight is not a registered tax agent and does not provide tax advice. You should consult an independent tax advisor for tax advice. You remain solely responsible for complying with all applicable accounting, tax and other laws. It is your responsibility to check that storage and access to the data input into Sharesight will comply with the laws applicable to you.`,
      },
      {
        id: getClauseId('7.3'),
        content: (
          <>
            To the maximum extent permitted by law, we disclaim all warranties, either express or
            implied, with respect to Sharesight, our performance methodology and the information and
            reports referred to in clauses <ClauseLink>7.1</ClauseLink> and{' '}
            <ClauseLink>7.2</ClauseLink> including but not limited to, warranties of accuracy,
            non-infringement, completeness, timeliness, currency, accessibility, security,
            merchantability or fitness for any particular purpose. Without limiting the foregoing:
          </>
        ),
        subclauses: [
          {
            id: getClauseId('7.3.a'),
            content: `access is provided on an “as is” and “as available” basis – we do not warrant that Sharesight will operate in an uninterrupted or error-free manner and we will not be responsible for the results of any actions taken in reliance on Sharesight;`,
          },
          {
            id: getClauseId('7.3.b'),
            content: `we do not warrant that any third party services that Sharesight integrates into, will operate, perform or function, or that the integration will perform; and`,
          },
          {
            id: getClauseId('7.3.c'),
            content: `information which is transmitted to or from you is transmitted at your sole risk and we do not accept any responsibility for any interference or damage to any computer system which arises in connection with you accessing Sharesight.`,
          },
        ],
      },
      {
        id: getClauseId('7.4'),
        content: (
          <>
            To the maximum extent permitted by law, we exclude all liability in contract, tort
            (including negligence), or otherwise, for any loss (including loss or corruption of data
            and loss of profits or savings) or damage resulting, directly or indirectly, from the
            use of, inability to use, or reliance on Sharesight and Third Party Services. We are not
            liable to you for indirect and consequential loss or for loss of revenue, loss of
            profits, failure to realise expected profits or savings and any other commercial or
            economic loss of any kind or loss or corruption of data, in contract, tort, under any
            statute or otherwise (including negligence) arising from or connected to these Terms of
            Use or your use of Sharesight. If you are not satisfied with Sharesight, your sole and
            exclusive remedy is to terminate these Terms of Use in accordance with clause{' '}
            <ClauseLink>8.5</ClauseLink>.
          </>
        ),
      },
      {
        id: getClauseId('7.5'),
        content: `Where legislation implies any guarantee, representation or warranty, and that legislation prohibits us from excluding or modifying the application of, or our liability under, any such implied term, that implied term will be deemed included but our liability will be limited for a breach of that term (other than a guarantee as to title or undisturbed possession) to one or more of the following:`,
        subclauses: [
          {
            id: getClauseId('7.5.a'),
            content: `the supplying of the services again; or`,
          },
          {
            id: getClauseId('7.5.b'),
            content: `the payment of the cost of having the services supplied again.`,
          },
        ],
      },
      {
        id: getClauseId('7.6'),
        content: `Our liability to you for loss or damage of any kind arising out of or in connection with these Terms of Use or your use of Sharesight is reduced to the extent (if any) that you cause or contribute to the loss or damage. This reduction applies whether our liability is in contract, tort (including negligence), under any statute or otherwise.`,
      },
      {
        id: getClauseId('7.7'),
        content: `Nothing in these Terms of Use is intended to limit or exclude any liability that cannot be excluded by law.`,
      },
      {
        id: getClauseId('7.8'),
        content: `Our maximum aggregate liability to you for any reason relating to these Terms of Use or your use and access to Sharesight will be limited in respect of all incidents in a 12 month period, to the Subscription Fees paid by you in the 12 months prior to the occurrence of the first incident.`,
      },
      {
        id: getClauseId('7.9'),
        content: `You will indemnify and keep us indemnified against all expenses, fines, losses (including loss of profit), damages and costs (Loss) sustained or incurred by us arising directly or indirectly from your breach of any of these Terms of Use, including any costs relating to the recovery of any Subscription Fees that are due but have not been paid by you. Without limiting the forgoing, you will indemnify us against any claims or Loss relating to:`,
        subclauses: [
          {
            id: getClauseId('7.9.a'),
            content: `our refusal to provide any person access to your information or data in accordance with these Terms of Use; and`,
          },
          {
            id: getClauseId('7.9.b'),
            content: `any claim by any third party that you do not have the right to use any data, or that your use of any data is a breach of a third party’s intellectual property rights.`,
          },
        ],
      },
    ],
  },
  {
    header: 'Termination',
    clauses: [
      {
        id: getClauseId('8.1'),
        content: `If an Event of Breach occurs, we may immediately (at our sole discretion) and without notice:`,
        subclauses: [
          {
            id: getClauseId('8.1.a'),
            content: `terminate your account and your use of Sharesight;`,
          },
          {
            id: getClauseId('8.1.b'),
            content: `suspend for any definite or indefinite period of time, your use of Sharesight; and/or`,
          },
          {
            id: getClauseId('8.1.c'),
            content: `suspend or terminate access to the data you have input into Sharesight.`,
          },
        ],
      },
      {
        id: getClauseId('8.2'),
        content: `An Event of Breach occurs if:`,
        subclauses: [
          {
            id: getClauseId('8.2.a'),
            content: `you breach any of these Terms of Use and fail to remedy the breach within 7 days after receiving notice requiring you to do so;`,
          },
          {
            id: getClauseId('8.2.b'),
            content: `you breach any of these Terms of Use where that breach is not capable of remedy;`,
          },
          {
            id: getClauseId('8.2.c'),
            content: `you are, become, or are deemed to be, insolvent or bankrupt;`,
          },
          {
            id: getClauseId('8.2.d'),
            content: `you make an assignment for the benefit of, or enter into or make any arrangement or composition with your creditors;`,
          },
          {
            id: getClauseId('8.2.e'),
            content: `you go into receivership or have a receiver, trustee and manager (or either of them) (including a statutory manager) appointed in respect of all or any of your property; or`,
          },
          {
            id: getClauseId('8.2.f'),
            content: `any resolution is passed, or any proceeding is commenced, for the winding up or liquidation of that other party (other than for the purposes of a solvent reconstruction).`,
          },
          {
            id: getClauseId('8.2.g'),
            content: `you breach any of these Terms of Use where that breach impairs Sharesight for other Sharesight users.`,
          },
        ],
      },
      {
        id: getClauseId('8.3'),
        content: (
          <>
            You will not be entitled to a refund if we discontinue or suspend your account under
            clause <ClauseLink>8.1</ClauseLink>.
          </>
        ),
      },
      {
        id: getClauseId('8.4'),
        content: `We may also discontinue or suspend your account, or reduce the services available to you, for any reason, by giving you one month’s notice.`,
      },
      {
        id: getClauseId('8.5'),
        content: (
          <>
            You may cancel your account for any reason by giving us one month’s notice. If you
            cancel your account under this clause <ClauseLink>8.5</ClauseLink>, you will not be
            entitled to any Subscription Fee paid in advance for the remaining period of the
            calendar month or year (as applicable) of your Subscription Plan.
          </>
        ),
      },
      {
        id: getClauseId('8.6'),
        content: (
          <>
            Where we discontinue or suspend your account or reduce the services available to you
            under clause <ClauseLink>8.4</ClauseLink> you may be entitled to a partial refund.
          </>
        ),
      },
      {
        id: getClauseId('8.7'),
        content: `Termination of these Terms of Use is without prejudice to any rights and obligations of the parties accrued up to and including the date of termination. On termination of these Terms of Use, you will remain liable for any accrued charges and amounts which become due for payment before or after termination and immediately cease to use Sharesight.`,
      },
      {
        id: getClauseId('8.8'),
        content: `Following the termination of your account, we reserve the right to delete all your data input into Sharesight in the normal course of operation. You acknowledge and confirm that your data cannot be recovered once it is deleted and we will have no liability for any deleted data.`,
      },
    ],
  },
  {
    header: 'Disputes',
    clauses: [
      {
        id: getClauseId('9.1'),
        content: `Where any dispute or difference arises in relation to the use of Sharesight or these Terms of Use, both parties agree to use all reasonable endeavours to resolve the dispute as soon as possible.`,
      },
    ],
  },
  {
    header: 'Miscellaneous',
    clauses: [
      {
        id: getClauseId('10.1'),
        content: `These Terms of Use together with the Terms of Agreement represent an entire agreement and supersede any prior representation, warranty, agreement or understanding. No amendment, modification or variation to these Terms of Use by you will be binding on us unless agreed in writing.`,
      },
      {
        id: getClauseId('10.2'),
        content: `Our successors (by merger, consolidation or otherwise) and assignees will have the benefit under these Terms of Use. You may not assign or transfer your account or all or any part of your rights and benefit under these Terms of Use without our prior written consent.`,
      },
      {
        id: getClauseId('10.3'),
        content: `If any provision of these terms and conditions becomes or is held invalid, unenforceable or illegal (whether partly or in full) for any reason, that provision (or part of that provision) will be severed from the remaining terms and conditions, which will continue in full force and effect.`,
      },
      {
        id: getClauseId('10.4'),
        content: `If you are an Australian customer, these Terms of Use, your use of Sharesight, any information contained on it, and any dispute arising out of such use of Sharesight will be governed by and construed in accordance with the laws of New South Wales, and the parties agree to submit to the non-exclusive jurisdiction of the courts of New South Wales. If you are not an Australian customer, these Terms of Use, your use of Sharesight, any information contained on it and any dispute arising out of such use of Sharesight will be governed by and construed in accordance with the laws of New Zealand, and the parties agree to submit to the exclusive jurisdiction of the courts of New Zealand.`,
      },
    ],
  },
];
